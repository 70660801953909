<template>
  <USelectMenu
    v-model="currentMenuLocale"
    :options="localesMenu"
    value-attribute="id"
  />
</template>

<script setup lang="ts">
const { locales, locale, setLocale, loadLocaleMessages } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const currentMenuLocale = ref(locale)
const localesMenu = computed(() => locales.value.map(locale => ({
  id: locale.code,
  label: locale.name,
})))

watch(currentMenuLocale, async (newLocale) => {
  await loadLocaleMessages(newLocale)
  await setLocale(newLocale)
  navigateTo(switchLocalePath(newLocale))
})
</script>
