export default function () {
  const transparentRoutes = useState(() => ['index', 'events-id', 'about', 'events', 'tickets', 'terms'])
  const route = useRoute()
  const getRouteBaseName = useRouteBaseName()

  const isCurrentRouteTransparent = computed(() => {
    return transparentRoutes.value.includes(getRouteBaseName(route)!)
  })

  return { transparentRoutes, isCurrentRouteTransparent }
}
