<template>
  <!--  <LayoutHeaderNative -->
  <!--    v-if="Capacitor.isNativePlatform()" -->
  <!--    :title="header.title" -->
  <!--    :back="header.back" -->
  <!--    :secondary="header.secondary" -->
  <!--    :is-transparent="isTransparent" -->
  <!--  /> -->
  <LayoutHeaderWeb
    :is-transparent="isTransparent"
  />
</template>

<script setup lang="ts">
const { isCurrentRouteTransparent } = useHeader()

// is transparent is true when the transparent is true and scroll is at the top
const isTransparent = computed(
  () => !(useWindowScroll().y.value > 0) && isCurrentRouteTransparent.value,
)
</script>
