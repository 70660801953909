<template>
  <div
    :class="{
      'sticky top-0 z-50 mb-[calc(-1*var(--header-height))] bg-gray-100 shadow-xl transition': true,
      'bg-transparent text-white !shadow-none': isTransparent,
      '!shadow-none': isMobileMenuOpen,
    }"
  >
    <div>
      <UContainer
        class="relative z-50"
      >
        <div
          class="z-50 flex justify-between"
        >
          <div class="relative z-50 flex py-4">
            <div class="flex shrink-0 items-center">
              <ULink :to="localePath('index')">
                <NuxtImg
                  class="block h-10"
                  src="/upfan.svg"
                  alt="Upfan"
                />
              </ULink>
            </div>
            <div class="hidden items-center md:ml-6 md:flex md:space-x-8">
              <ULink
                v-for="link in links"
                :key="link.to"
                :to="link.to"
                class="inline-flex items-center font-medium"
                :class="isTransparent ? 'text-white hover:text-gray-200' : 'text-gray-500 hover:text-gray-900'"
                :active-class="isTransparent ? '!text-upfan-600' : '!text-gray-900'"
              >
                {{ link.title }}
              </ULink>
            </div>
          </div>
          <div class="hidden md:ml-6 md:flex md:items-center">
            <div v-if="!authStore.isSignedIn">
              <UButton
                v-for="link in signedOutLinks"
                :key="link.to"
                :variant="link.variant"
                size="xl"
                :to="link.to"
              >
                {{ link.title }}
              </UButton>
            </div>
            <div v-else>
              <UButton
                v-for="link in signedInLinks"
                :key="link.to"
                :variant="link.variant"
                size="xl"
                :to="link.to"
                @click="link.click"
              >
                {{ link.title }}
              </UButton>
            </div>
          </div>
          <div class="flex items-center md:hidden">
            <span class="sr-only">Open main menu</span>
            <UButton
              icon="i-heroicons-bars-3"
              size="xl"
              @click="isMobileMenuOpen = true"
            />
          </div>
        </div>
      </UContainer>

      <USlideover
        v-model="isMobileMenuOpen"
        side="top"
        :ui="{ background: 'bg-gray-100 shadow-xl' }"
      >
        <div>
          <div class="flex justify-between p-4 sm:px-6 lg:px-8">
            <ULink
              :to="localePath('index')"
              @click="isMobileMenuOpen = false"
            >
              <NuxtImg
                class="block h-10"
                src="/upfan.svg"
                alt="Upfan"
              />
            </ULink>
            <UButton
              icon="i-heroicons-x-mark"
              size="xl"
              @click="isMobileMenuOpen = false"
            />
          </div>

          <div
            v-for="link in links"
            :key="link.to"
            class="block text-base font-medium text-gray-500 hover:bg-gray-200 hover:text-gray-900"
          >
            <ULink
              :to="link.to"
              active-class="text-gray-900"
              class="block size-full px-4 py-2"
              @click="isMobileMenuOpen = false"
            >
              {{ link.title }}
            </Ulink>
          </div>
        </div>
        <div>
          <div class="mt-3 space-y-1">
            <div v-if="!authStore.isSignedIn">
              <div
                v-for="link in signedOutLinks"
                :key="link.to"
                class="mb-2 block px-8 text-base"
              >
                <UButton
                  :variant="link.variant"
                  size="xl"
                  :to="link.to"
                  block
                  @click="isMobileMenuOpen = false"
                >
                  {{ link.title }}
                </UButton>
              </div>
            </div>
            <div v-else>
              <div
                v-for="link in signedInLinks"
                :key="link.to"
                class="mb-2 block px-8 text-base"
              >
                <UButton
                  :variant="link.variant"
                  size="xl"
                  :to="link.to"
                  block
                  @click="link.click"
                >
                  {{ link.title }}
                </UButton>
              </div>
            </div>
          </div>
        </div>
      </USlideover>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ButtonVariant } from '#ui/types'

defineProps<{
  isTransparent: boolean
}>()

const authStore = useAuthStore()
const localePath = useLocalePath()

const { t } = useI18n()

const isMobileMenuOpen = ref(false)

const links = computed(() => [
  { title: t('header.home'), to: localePath('index') },
  { title: t('header.market'), to: localePath('events') },
  { title: t('header.tickets'), to: localePath('tickets') },
  { title: t('header.about'), to: localePath('about') },
])

const signedOutLinks = computed<{ title: string, to: string, variant: ButtonVariant }[]>(() => [
  { title: t('header.sign-in'), to: localePath('auth-signin'), variant: 'link' },
  { title: t('header.create-account'), to: localePath('auth-signup'), variant: 'solid' },
])

const signedInLinks = computed<{ title: string, to?: string, variant: ButtonVariant, click: () => void }[]>(() => [
  { title: t('header.profile'), to: undefined, variant: 'solid', click: () => {
    navigateTo(localePath('auth-profile'))
    isMobileMenuOpen.value = false
  } },
])
</script>
