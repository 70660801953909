import { $fetch } from 'ofetch'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const toast = useToast()
  const authStore = useAuthStore()
  const api = $fetch.create({
    baseURL: runtimeConfig.public.apiBaseUrl,
    onRequest({ options }) {
      if (authStore.isSignedIn) {
        const headers = options.headers ||= {}
        if (Array.isArray(headers)) {
          headers.push(['Authorization', `Bearer ${authStore.token}`])
        }
        else if (headers instanceof Headers) {
          headers.set('Authorization', `Bearer ${authStore.token}`)
        }
        else {
          headers.Authorization = `Bearer ${authStore.token}`
        }
      }
    },
    onResponseError({ response }) {
      if (response.status === 401) {
        authStore.$patch({
          user: null,
          token: null,
        })
      }
    },
  })

  return {
    provide: {
      api,
    },
  }
})
