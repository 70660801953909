import { default as indexRZJHGTDvaLMeta } from "/workspace/app/pages/about/index.vue?macro=true";
import { default as profileh2wyqMO2jYMeta } from "/workspace/app/pages/auth/profile.vue?macro=true";
import { default as resetK7YNTDR4lSMeta } from "/workspace/app/pages/auth/reset.vue?macro=true";
import { default as signinWZbCAdwJ2pMeta } from "/workspace/app/pages/auth/signin.vue?macro=true";
import { default as signupq71H0db5HKMeta } from "/workspace/app/pages/auth/signup.vue?macro=true";
import { default as index5hxsCNoisuMeta } from "/workspace/app/pages/events/[id]/cart/index.vue?macro=true";
import { default as index4xYESqMkGkMeta } from "/workspace/app/pages/events/[id]/index.vue?macro=true";
import { default as indexUvlogGr5W5Meta } from "/workspace/app/pages/events/index.vue?macro=true";
import { default as index6n3ZPBUsMaMeta } from "/workspace/app/pages/index.vue?macro=true";
import { default as indexde4LDRdPaEMeta } from "/workspace/app/pages/terms/index.vue?macro=true";
import { default as index1C8FBr5yoPMeta } from "/workspace/app/pages/tickets/index.vue?macro=true";
import { default as component_45stubmKyKxp5WTxMeta } from "/workspace/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmKyKxp5WTx } from "/workspace/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___sk",
    path: "/o-nas",
    component: () => import("/workspace/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("/workspace/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/workspace/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-profile___sk",
    path: "/profil",
    meta: profileh2wyqMO2jYMeta || {},
    component: () => import("/workspace/app/pages/auth/profile.vue").then(m => m.default || m)
  },
  {
    name: "auth-profile___cs",
    path: "/cs/profil",
    meta: profileh2wyqMO2jYMeta || {},
    component: () => import("/workspace/app/pages/auth/profile.vue").then(m => m.default || m)
  },
  {
    name: "auth-profile___en",
    path: "/en/profile",
    meta: profileh2wyqMO2jYMeta || {},
    component: () => import("/workspace/app/pages/auth/profile.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset___sk",
    path: "/obnovit-heslo",
    meta: resetK7YNTDR4lSMeta || {},
    component: () => import("/workspace/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset___cs",
    path: "/cs/obnovit-heslo",
    meta: resetK7YNTDR4lSMeta || {},
    component: () => import("/workspace/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset___en",
    path: "/en/reset-password",
    meta: resetK7YNTDR4lSMeta || {},
    component: () => import("/workspace/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___sk",
    path: "/prihlasenie",
    meta: signinWZbCAdwJ2pMeta || {},
    component: () => import("/workspace/app/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___cs",
    path: "/cs/prihlaseni",
    meta: signinWZbCAdwJ2pMeta || {},
    component: () => import("/workspace/app/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___en",
    path: "/en/signin",
    meta: signinWZbCAdwJ2pMeta || {},
    component: () => import("/workspace/app/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___sk",
    path: "/registracia",
    meta: signupq71H0db5HKMeta || {},
    component: () => import("/workspace/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___cs",
    path: "/cs/registrace",
    meta: signupq71H0db5HKMeta || {},
    component: () => import("/workspace/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___en",
    path: "/en/signup",
    meta: signupq71H0db5HKMeta || {},
    component: () => import("/workspace/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "events-id-cart___sk",
    path: "/podujatia/:id()/kosik",
    component: () => import("/workspace/app/pages/events/[id]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id-cart___cs",
    path: "/cs/udalosti/:id()/kosik",
    component: () => import("/workspace/app/pages/events/[id]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id-cart___en",
    path: "/en/events/:id()/cart",
    component: () => import("/workspace/app/pages/events/[id]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___sk",
    path: "/podujatia/:id()",
    component: () => import("/workspace/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___cs",
    path: "/cs/udalosti/:id()",
    component: () => import("/workspace/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___en",
    path: "/en/events/:id()",
    component: () => import("/workspace/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events___sk",
    path: "/podujatia",
    component: () => import("/workspace/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___cs",
    path: "/cs/udalosti",
    component: () => import("/workspace/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___en",
    path: "/en/events",
    component: () => import("/workspace/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/",
    meta: index6n3ZPBUsMaMeta || {},
    component: () => import("/workspace/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    meta: index6n3ZPBUsMaMeta || {},
    component: () => import("/workspace/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: index6n3ZPBUsMaMeta || {},
    component: () => import("/workspace/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "terms___sk",
    path: "/obchodne-podmienky",
    component: () => import("/workspace/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "terms___cs",
    path: "/cs/obchodni-podminky",
    component: () => import("/workspace/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/workspace/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets___sk",
    path: "/vstupenky",
    meta: index1C8FBr5yoPMeta || {},
    component: () => import("/workspace/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets___cs",
    path: "/cs/vstupenky",
    meta: index1C8FBr5yoPMeta || {},
    component: () => import("/workspace/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets___en",
    path: "/en/tickets",
    meta: index1C8FBr5yoPMeta || {},
    component: () => import("/workspace/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubmKyKxp5WTxMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubmKyKxp5WTx
  },
  {
    name: component_45stubmKyKxp5WTxMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubmKyKxp5WTx
  },
  {
    name: component_45stubmKyKxp5WTxMeta?.name,
    path: "/sk-sitemap.xml",
    component: component_45stubmKyKxp5WTx
  },
  {
    name: component_45stubmKyKxp5WTxMeta?.name,
    path: "/cs-sitemap.xml",
    component: component_45stubmKyKxp5WTx
  },
  {
    name: component_45stubmKyKxp5WTxMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubmKyKxp5WTx
  }
]