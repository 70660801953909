import { Settings } from 'luxon'
import type { Composer } from 'vue-i18n'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const locale = (nuxtApp.$i18n as Composer).locale
  Settings.defaultLocale = locale.value
  watch(locale, (newLocale) => {
    Settings.defaultLocale = newLocale
  })
})
