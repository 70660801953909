import { Capacitor } from '@capacitor/core'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  onPrehydrate(() => {
    document.documentElement.style.setProperty('--header-height', '72px')
  })
  onMounted(() => {
    if (Capacitor.isNativePlatform()) {
      const html = document.querySelector('html')
      if (html) {
        html.style.setProperty('user-select', 'none')
        html.style.setProperty('-webkit-user-select', 'none')
        html.style.setProperty('-webkit-user-drag', 'none')
        html.style.setProperty('-webkit-touch-callout', 'none')
      }
      document.documentElement.style.setProperty('--header-height', 'calc(49px + env(safe-area-inset-top))')
    }
  })
})
