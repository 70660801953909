import { Capacitor } from '@capacitor/core'
import type { User } from '~/types'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const user = ref<User | null>(null)
    const token = ref<string | null>(null)

    const isSignedIn = computed(() => !!user.value && !!token.value)
    async function signUp(data: {
      firstName: string
      lastName: string
      email: string
      password: string
    }) {
      const { $api } = useNuxtApp()
      const response = await $api<{
        user: User
        token: string
      }>('/api/v1/auth/signup', {
        method: 'POST',
        body: data,
      })
      user.value = response.user
      token.value = response.token
    }

    async function signIn(data: { email: string, password: string }) {
      const { $api } = useNuxtApp()
      const response = await $api<{
        user: User
        token: string
      }>('/api/v1/auth/signin', {
        method: 'POST',
        body: data,
      })
      user.value = response.user
      token.value = response.token
    }

    async function resetPwd(data: { email: string }) {
      const { $api } = useNuxtApp()
      await $api<any>('/api/v1/auth/reset-request', {
        method: 'POST',
        body: data,
      })
    }

    async function changePwd(data: { password: string, token: string }) {
      const { $api } = useNuxtApp()
      await $api<any>('/api/v1/auth/reset', {
        method: 'POST',
        body: data,
      })
    }

    async function me() {
      const { $api } = useNuxtApp()
      if (!isSignedIn.value) {
        return
      }
      try {
        user.value = await $api('/api/v1/auth/me', {
          method: 'GET',
        })
      }
      catch { /* empty */ }
    }

    async function signOut() {
      const { $api } = useNuxtApp()
      try {
        await $api('/api/v1/auth/signout', {
          method: 'POST',
        })
      }
      catch { /* empty */ }
      finally {
        user.value = null
        token.value = null
      }
    }

    return { user, token, signUp, me, signOut, signIn, resetPwd, changePwd, isSignedIn }
  },
  {
    persist: {
      storage: Capacitor.isNativePlatform() ? persistedState.localStorage : persistedState.cookies,
    },
  },
)
