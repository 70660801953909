<template>
  <div
    id="root"
  >
    <NuxtLoadingIndicator />
    <NuxtRouteAnnouncer />

    <!--    <div -->
    <!--      v-if="Capacitor.isNativePlatform()" -->
    <!--      class="fixed left-0 top-0 z-[1000] h-[env(safe-area-inset-top)] w-screen bg-gray-100" -->
    <!--    /> -->
    <div
      class="min-h-[calc(100vh-env(safe-area-inset-top)-env(safe-area-inset-bottom))]"
    >
      <LayoutHeader />
      <NuxtLayout>
        <div
          :class="{
            'pb-10': true,
          }"
        >
          <NuxtPage
            class="flex min-h-[calc(100vh-env(safe-area-inset-top)-env(safe-area-inset-bottom)-2.5rem)] flex-col"
            :class="{ 'pt-32': !isCurrentRouteTransparent }"
          />
        </div>
      </NuxtLayout>
      <LayoutFooter />
    </div>
    <div
      v-if="Capacitor.isNativePlatform()"
      class="fixed bottom-0 left-0 z-[1000] h-[env(safe-area-inset-bottom)] w-screen bg-transparent"
    />
    <UNotifications />
  </div>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'

const { isCurrentRouteTransparent } = useHeader()

const authStore = useAuthStore()
await authStore.me()
</script>

<style>
#root {
  @apply bg-gray-100 pt-safe pb-safe pl-safe pr-safe;
}
</style>
