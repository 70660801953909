import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/workspace/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/workspace/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/workspace/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/workspace/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/workspace/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_X7whXyrIWR from "/workspace/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/workspace/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/workspace/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/workspace/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/workspace/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/workspace/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_slUmq5TON0 from "/workspace/app/plugins/api.ts";
import header_RPGvbJyzi1 from "/workspace/app/plugins/header.ts";
import lightGallery_HN2IP0FRJ5 from "/workspace/app/plugins/lightGallery.ts";
import luxon_JWHRGPoQ6G from "/workspace/app/plugins/luxon.ts";
import posthog_client_8TXiKjlKOS from "/workspace/app/plugins/posthog.client.ts";
import sentry_client_a4hAB0MOGi from "/workspace/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_AUP22rrBAc,
  api_slUmq5TON0,
  header_RPGvbJyzi1,
  lightGallery_HN2IP0FRJ5,
  luxon_JWHRGPoQ6G,
  posthog_client_8TXiKjlKOS,
  sentry_client_a4hAB0MOGi
]