export default defineAppConfig({
  ui: {
    primary: 'upfan',
    carousel: {
      container: 'p-4',
    },
    input: {
      variant: {
        outline: 'bg-white',
      },
    },
    select: {
      variant: {
        outline: 'bg-white',
      },
    },
    modal: {
      padding: 'p-safe-offset-4',
      wrapper: 'z-[100]',
      inner: 'z-[120]',
      overlay: {
        base: 'z-[100]',
      },
    },
    notification: {
      wrapper: 'mb-safe',
    },
  },
})
